<template>
  <el-table
    ref="inParkTable"
    v-loading="loading"
    :data="tableData"
    :default-sort="{ prop: 'updatedTime', order: 'descending' }"
    @sort-change="handleSortChange"
    row-key="parkRecordId"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" :reserve-selection="true"></el-table-column>
    <el-table-column
      align="left"
      :prop="item.prop"
      :label="item.label"
      :min-width="item.width"
      v-for="item in tableCols"
      :key="item.prop"
      :formatter="item.formatter"
      :fixed="item.fixed"
      :sortable="item.sortable"
      :sort-orders="['ascending', 'descending']"
    ></el-table-column>
    <el-table-column align="left" label="操作" width="80">
      <template slot-scope="scope">
        <el-button type="text" @click="$emit('detailShow', scope.row)">详情</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      multipleSelection: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_record_ID"),
          width: "180",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "150",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "180",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return this.$t("list.Unlicensed_car");
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "groupType",
          label: this.$t("list.Vehicle_group_type"),
          width: "100",
          formatter: (row, column) => {
            if (row.groupType == 1) {
              return this.$t("list.Free_car");
            } else if (row.groupType == 2) {
              return this.$t("list.Temporary_vehicle");
            } else if (row.groupType == 3) {
              return this.$t("list.Official_vehicle");
            } else if (row.groupType == 4) {
              return this.$t("list.Monthly_rental_car");
            }
          },
        },
        {
          prop: "entryChannelName",
          label: this.$t("list.entry_ChannelName"),
          width: "100",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
          sortable: "custom",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "140",
        },
      ],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("selectChange", val);
    },
    // 排序
    handleSortChange(value) {
      this.$emit("sortChange", value);
    },
  },
};
</script>
