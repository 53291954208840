var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { data: _vm.tableData },
    },
    [
      _vm._l(_vm.tableCols, function (item) {
        return _c("el-table-column", {
          key: item.prop,
          attrs: {
            align: "left",
            prop: item.prop,
            label: item.label,
            "min-width": item.width,
            formatter: item.formatter,
            fixed: item.fixed,
          },
        })
      }),
      _c("el-table-column", {
        attrs: { align: "left", label: "操作", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("detailShow", scope.row)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }